<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        src="@/assets/btn_close.png"
        alt=""
      />
      <div class="top">
        <div class="d-flex flex-row">
          <h3>{{ $t("headerNavAuctions") }}</h3>
          <img
            class="rule"
            @click="openRules"
            src="@/assets/icons/icon_wen.png"   
            alt=""
            title="Viewing auction rules"
          />
        </div>
        <h3 class="pupils">{{ name }}</h3>
      </div>
       <div class="form">
        <div class="title ">Token</div>
        <ChainSelectInput @change="chainChange" ></ChainSelectInput>
      </div>
         <div class="showLimit d-flex flex-row">
         <span class="minTxt">Minimum price <span class="limintContent ml-1">{{currentMinPrice}} {{chainName}}</span></span> 
         <span class="maxTxt ml-3">Maximum price <span class="limintContent ml-1">{{currentMaxPrice}} {{chainName}}</span></span> 
      </div>
      <div class="form mt-5">
        <div class="ti-in">
          <span class="title">{{ $t("auctionStartBid") }}</span>
          <span class="subtitle">{{ $t("BidsBelow") }}</span>
          <v-text-field
          class="text-file"
            v-model="startBid"
            outlined
            :suffix="chain.name"
            type="input"
            :rules="[ rules.errorStrat ]"
          ></v-text-field>
        </div>
      </div>
      <div class="form">
        <div class="ti-in">
          <span class="title">{{ $t("auctionReservePrice") }}</span>
          <span class="subtitle">{{ $t("afterTheAuction") }}</span>
          <v-text-field
            v-model="ReserveBid"
            outlined
            :suffix="chain.name"
            type="input"
            
            :rules="[ rules.errorReserveBid ]"
          ></v-text-field>
        </div>
      </div>
      <div class="form">
        <div class="ti-in">
          <span class="title">{{ $t("popupAuctionFixedPrice") }}</span>
          <span class="subtitle">{{ $t("buyoutPriceNotice") }}</span>
          <v-text-field
            v-model="fixPrice"
            outlined
           :suffix="chain.name"
            type="input"
            :rules="[ rules.errorfixPrice ]"
          ></v-text-field>
        </div>
      </div>
      <div class="form">
        <div class="ti-in">
          <span class="title mb-n3" >{{ $t("popupAuctionStartDate") }}</span>
          <v-datetime-picker label="" v-model="datetime">
            <template v-slot:dateIcon>
              <img
                style="
                  display: inline;
                  width: 24px;
                  height: 24px;
                  margin: 0;
                  background-color: transparent;
                "
                src="@/assets/icons/icon-calendar.png"
                alt
              />
            </template>
            <template v-slot:timeIcon>
              <img
                style="
                  display: inline;
                  width: 24px;
                  height: 24px;
                  margin: 0;
                  background-color: transparent;
                "
                src="@/assets/icons/icon-clock.png"
                alt
              />
            </template>
          </v-datetime-picker>
        </div>
      </div>
      <div class="form mt-lg-3">
        <div class="ti-in">
          <span class="title">{{ $t("popupAuctionEndDate") }}</span>
          <v-select
            :items="list"
            :label="this.$t('auctionTime')"
            v-model="TimeValue"
            item-text="text"
            item-value="value"
            solo
            any
          ></v-select>
          <span class="subtitle">{{ $t("ruleLast") }}</span>
        </div>
      </div>

      <div class="footer">
      
     
        <button class="sub" :class="{'sub-dis': isPay}"  @click="subCommit">{{
          $t("popupTransferCommit")
        }}</button>
     
      </div>
    </v-card>
    <v-snackbar
      v-model="isShowFalse"
      color="error"
      centered
      timeout="2000"
      style="opacity: 0.8"
      ><span style="color: white; font-size: 20px">{{
        falseTitle
      }}</span></v-snackbar
    >
    <PromptBox ref="promptBox"></PromptBox>
    <uComponents ref="ucom"></uComponents>
  </div>
</template>

<script>
import { addDate } from "../../../src/utils/helper";
import api from "@/api";
import PromptBox from "@/components/PromptBox.vue";
import ChainSelectInput from "@/components/ChainSelectInput.vue";
import Web3 from "web3";
import Button from '@/views/detail/components/Button.vue';
const web3Obj = new Web3();

const { toShowValue,tokenId2Index} = require("../../utils/helper");


export default {
  name: "transfer",
  components: { PromptBox,ChainSelectInput, Button },
  data: () => ({
    open: true,
    startBid: "",
    fixPrice: "",
    switch1: "false",
    defaultFee: "0.1",
    userBalance: "",

    TimeValue: 1,
    ReserveBid: "",
    isShowFalse: false,
    falseTitle: "",
    isPay: false,
    datetime: "",
    currentMinPrice:'',
    currentMaxPrice:'',
    lockHash:'',
    userInfo:{},
     chainName:'LRC',
     royaltyPercentage:"",
     totalRoyalty:"",
    rules: {
      // errorPrice:(value) => {
      //   if(value==0){
      //     return "Out of price range"; 
      //   }

      //   const pattern = /^((\+?[0-9][0-9]{0,8})\.([0-9]{1,4})$)|^(\+?[0-9][0-9]{0,8})$/;
      //   return pattern.test(value) || "Out of price range";
      //  },
      
    },
    chain: { },
    auctionPrice:''
  }),
  props: {
    address: {
      type: String,
    },
    onSaleTokenIds: {
      type: Array,
    },
    offSaleTokenIds: {
      type: Array,
    },
    name: {
      type: String,
    },
    parentValue: {
      type: Object,
    },
  },
  created() {
    this.datetime = new Date();
  },
  watch:{
    startBid(startBid){
         this.rules.errorStrat = (value) => {
		  
        if(startBid){
           if(startBid<parseFloat(this.currentMinPrice) || startBid>parseFloat(this.currentMaxPrice)){
          return "Out of price range"; 
        }
        const pattern = /^\d*(?:\.\d{0,4})?$/;
        return pattern.test(startBid) || "Out of price range";
       }
       
    }
    },
    ReserveBid(ReserveBid){
          this.rules.errorReserveBid = (value) => {
		  
        if(ReserveBid){
           if(ReserveBid<parseFloat(this.currentMinPrice) || ReserveBid>parseFloat(this.currentMaxPrice)){
          return "Out of price range"; 
        }
        const pattern = /^\d*(?:\.\d{0,4})?$/;
        return pattern.test(ReserveBid) || "Out of price range";
       }
       
    }
    },
    fixPrice(fixPrice){
           this.rules.errorfixPrice = (value) => {
		  
        if(fixPrice){
           if(fixPrice<parseFloat(this.currentMinPrice) || fixPrice>parseFloat(this.currentMaxPrice)){
          return "Out of price range"; 
        }
        const pattern = /^\d*(?:\.\d{0,4})?$/;
        return pattern.test(fixPrice) || "Out of price range";
       }
       
    }

    }
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
      list:function () {
      return  [
          {
            text: this.$t('auctionTime'),
            value: 1,
          },
          {
            text: this.$t('auctionTime48'),
            value: 2,
          },
          {
            text: this.$t('auctionTime72'),
            value: 3,
          },
        ];
      
    }
  },
  async mounted() {
    
    // init
      this.royaltyPercentage=this.parentValue.royaltyPercentage;
	  if(this.royaltyPercentage==null){
		this.royaltyPercentage=0;  
    }
    let uptickRoy = process.env.VUE_APP_UPTICK_ROYALTY_PERCENTAGE
	api.home.getSystemParams({keys:"uptickServiceFee"}).then((systemParams) => {
		
	 uptickRoy=systemParams.data[0].value
	this.totalRoyalty = Number(this.royaltyPercentage) + Number(uptickRoy)
	
	});
	
    
      this.chain.name = this.$walletUnit;
      this.chain.tokenId = 1; 
      this.NftName = this.name;
      let amount = await this.LRCHandler.getUserBalances(1);
      this.priceLimit = await this.LRCHandler.getPriceLimit(
          this.parentValue.contractAddress
      );
      // this.currentMinPrice =  toShowValue(web3Obj,1,this.priceLimit[1].marketOrderInfo["minimum"]);
      // this.currentMaxPrice =  toShowValue(web3Obj,1,this.priceLimit[1].marketOrderInfo["maximum"]);
      //  todo dev 
      let currentMinPrice =  toShowValue(web3Obj,1,this.priceLimit[3].marketOrderInfo["minimum"]);
      this.currentMaxPrice =  toShowValue(web3Obj,1,this.priceLimit[3].marketOrderInfo["maximum"]);
    
    let tradeCost = this.priceLimit[3].tradeCost;
    let tradePrice =  Number(toShowValue(web3Obj,1,tradeCost) *3);
    console.log("wxl --- tradePrice",tradePrice)
    if(tradePrice > currentMinPrice){
      this.currentMinPrice = parseFloat(Math.ceil(tradePrice*10000))/10000
      
    }else{
      this.currentMinPrice =  parseFloat(Math.ceil(currentMinPrice*10000))/10000
    }
  
      
  },
  methods: {
    async chainChange(chain,coinname) {
      this.chainName = coinname
      let amount = await this.LRCHandler.getUserBalances(chain.tokenId);
      this.chain = chain;
      
      let index = tokenId2Index(chain.tokenId)
      
      let currentMinPrice =  toShowValue(web3Obj,chain.tokenId,this.priceLimit[index].marketOrderInfo["minimum"]);
      this.currentMaxPrice =  toShowValue(web3Obj,chain.tokenId,this.priceLimit[index].marketOrderInfo["maximum"]);
    let tradeCost = this.priceLimit[index].tradeCost;
    let tradePrice = Number(toShowValue(web3Obj,chain.tokenId,tradeCost) *3) ;
    console.log(" ",tradePrice)
    if(tradePrice > currentMinPrice){
      this.currentMinPrice = parseFloat(Math.ceil(tradePrice*10000))/10000
      
    }else{
      this.currentMinPrice =  parseFloat(Math.ceil(currentMinPrice*10000))/10000
    }

    },
    //提交转送数据
    async subCommit() {
      try {
        if (!Number(this.startBid) || Number(this.startBid) <= 0) {
          this.$toast("error", this.$t("LowerThanStartBid"));
          return;
        }
        if (Number(this.fixPrice) != "") {
          this.startBid = Number(this.startBid)
         this.fixPrice = Number(this.fixPrice)
          if (Number(this.startBid) >= Number(this.fixPrice)) {
            this.$toast("error", this.$t("auctionPriceOnsaleError"));
            return;
          }
        }
        if(Number(this.ReserveBid) != '' && Number(this.fixPrice) == '')
        {
          this.startBid = Number(this.startBid)
         this.ReserveBid = Number(this.ReserveBid)
          if(Number(this.startBid) >= Number(this.ReserveBid) )
          {
            this.$toast("error", this.$t("auctionPriceOnsaleError"));
            return;
          }

        }
        if(Number(this.ReserveBid) == '' && Number(this.fixPrice) != '')
        {
          this.startBid = Number(this.startBid)
         this.fixPrice = Number(this.fixPrice)
          if(Number(this.ReserveBid) >= Number(this.fixPrice) )
          {
            this.$toast("error", this.$t("auctionPriceOnsaleError"));
            return;
          }


        }
        if(Number(this.ReserveBid) != '' && Number(this.fixPrice) != '')
        {
         this.startBid = Number(this.startBid)
         this.ReserveBid = Number(this.ReserveBid)
         this.fixPrice = Number(this.fixPrice)
         if(   Number(this.startBid) >= Number(this.ReserveBid) ||
          Number(this.ReserveBid) >= Number(this.fixPrice))
        {
            this.$toast("error", this.$t("auctionPriceOnsaleError"));
            return;
        }

        }
		
		if(this.startBid){
		    if(this.startBid<parseFloat(this.currentMinPrice) || this.startBid>parseFloat(this.currentMaxPrice)){
		  this.$toast("error", "Out of price range");
		  return;
		 }
	
		}
		 if(this.ReserveBid){
		    if(this.ReserveBid<parseFloat(this.currentMinPrice) || this.ReserveBid>parseFloat(this.currentMaxPrice)){
		  this.$toast("error", "Out of price range");
		  return;
		 }
		 
		}
		if(this.fixPrice){
		    if(this.fixPrice<parseFloat(this.currentMinPrice) || this.fixPrice>parseFloat(this.currentMaxPrice)){
		  this.$toast("error", "Out of price range");
		  return;
		 }
		}
		  
        if (new Date() - this.datetime > 3600000) {
          this.datetime = null;
          this.$toast("error", this.$t("LowerThanCurrentTime"));
          return;
        }
         this.isPay = true;
        let startTimeStamp = parseInt(this.datetime.valueOf());
        let endTimeStamp = addDate(startTimeStamp, this.TimeValue);
        let validUntil;
         if(this.TimeValue == 1){
		  // 日期前+15天
        validUntil = Math.round(Date.now() / 1000) +  86400 + 1296000
      }else if(this.TimeValue == 2){
         validUntil = Math.round(Date.now() / 1000) + 172800 + 1296000
      }else if(this.TimeValue == 3){
         validUntil = Math.round(Date.now() / 1000) + 259200 + 1296000
      }

        // acountid
      	let user = await api.home.loopringUserInfo({
				address: this.$store.state.did,
			});
	
			if (user) {
				this.userInfo = user.data;
			}

        // loopring 拍卖逻辑
      let resTokenId=await api.home.getNftTokenId({"owner":this.$store.state.did,"tokenAddress": this.parentValue.contractAddress,"nftId": this.parentValue.nftId});
		  if(resTokenId==null||resTokenId.data==null){
        this.$toast("error", "invalid NFT");
        return ;   
      }
      //this.ReserveBid  保底价  this.startBid 起拍价  this.fixPrice 一口价
      if(this.ReserveBid){
        this.auctionPrice = this.ReserveBid

      }else{
         this.auctionPrice = this.startBid
      }
   
       // lock
  let lockedUntil = parseInt(endTimeStamp/1000);

  let resLock = await this.LRCHandler.Lock(this.userInfo.accountId,resTokenId.data,lockedUntil,"SELL",1,"lockNft").then((lockRes)=>{
console.log(lockRes)
 if(lockRes!=null&&lockRes.hash){
		  this.lockHash = lockRes.hash
	 }else{
		  this.$toast("error", lockRes.message);
	 }
  })
  if(!this.lockHash){
	   this.isPay = false
	  return 
  }
  debugger
  let signature = await this.LRCHandler.makerOrder(
    resTokenId.data,
    this.parentValue.nftData,
    "1",
    // "ipfs://"+this.parentValue.imgUrl,
    this.chain.tokenId,
    this.auctionPrice,
    this.totalRoyalty,
    validUntil
  );
  console.log("wxl ---- signature",signature)
    if(!signature){
          this.$toast("error", "Signature request was rejected");
               this.isPay = false;
         return
    }
    //    if(signature == undefined){
    //   this.$toast("error", this.$t("transactionInprogressError"));
    //      this.isPay = false
    //      return
    // }
        let makerOrderJson = {
        "exchange": signature.exchange,
        "accountId": signature.accountId,
        "storageId": signature.storageId,
        "sellToken": signature.sellToken,
        "buyToken": signature.buyToken,
        "allOrNone": signature.allOrNone,
        "fillAmountBOrS": signature.fillAmountBOrS,
        "validUntil": signature.validUntil,
        "maxFeeBips": signature.maxFeeBips
      };
      let buyLimit = "";
      if (this.switch1 == true) {
          buyLimit = this.amount;
      } else {
          buyLimit = 0;
      }

      let param = {
        "owner": this.$store.state.did,
        "nftAddress": this.parentValue.contractAddress,
        "nftId": this.parentValue.nftId,
        "marketPrice": this.auctionPrice,
        "tokenType": this.chain.name,
		    "limit": buyLimit,
        "makerOrderJson": JSON.stringify(makerOrderJson),
        "signature": signature.eddsaSignature,
        "listAmount":this.parentValue.amount,
        "makerType":2,
        "startTime":startTimeStamp,
        "endTime":endTimeStamp,
        "buyoutPrice":this.fixPrice,
        "reservePrice":this.startBid,
        "guaranteedPrice":this.ReserveBid,   
        "lockAssetHash" :this.lockHash
      };
      let nftlist_res = await api.home.nftList(param);
   
    if(nftlist_res.msg == "Success")
    {
      this.$toast("success", this.$t("transactionInprogressSuccess")).then(() => {
        this.$emit("getOpen", false)
             this.isPay = true
          });  
           window.eventBus.$emit("collectionPay");
    }else{
       this.$toast("error", this.$t("transactionInprogressError"));
         this.isPay = false
    }
   }
       catch (e) {
         console.log("wxl --- error",e)
        this.$toast("error", this.$t("transactionInprogressError"));
         this.isPay = false
      }
    },
    closeDialog() {
      console.log("wxl ---- closeDialog" )
      this.open = false;
      this.$emit("getOpen", this.open);
    },
    openRules() {
      if (this.$vuetify.lang.current == "en") {
        let routeUrl = this.$router.resolve({
          path: "/auctionrule_en",
          query: { id: 96 },
        });
        window.open(routeUrl.href, "_blank");
      } else if(this.$vuetify.lang.current == "zh"){
        let routeUrl = this.$router.resolve({
          path: "/auctionrule_zh",
          query: { id: 97 },
        });

        window.open(routeUrl.href, "_blank");
      }
    else if(this.$vuetify.lang.current == "ja"){
        let routeUrl = this.$router.resolve({
          path: "/auctionrule_en",
          query: { id: 96 },
        });
        
        window.open(routeUrl.href, "_blank");
      }
      else if(this.$vuetify.lang.current == "ko"){
        let routeUrl = this.$router.resolve({
         path: "/auctionrule_en",
          query: { id: 96 },
        });
        
        window.open(routeUrl.href, "_blank");
      }else if(this.$vuetify.lang.current == "pt"){
        let routeUrl = this.$router.resolve({
         path: "/auctionrule_en",
          query: { id: 96 },
        });
        
        window.open(routeUrl.href, "_blank");
      }
    },
     onLangChange() {
       this.list= [
          {
            text: this.$t('auctionTime'),
            value: 1,
          },
          {
            text: this.$t('auctionTime48'),
            value: 2,
          },
          {
            text: this.$t('auctionTime72'),
            value: 3,
          },
        ];
     }
  },
};
</script>

<style lang="scss" scoped>
.rule {
  width: 28px;
  height: 28px;
  display: inline-block;
  margin-top: 48px;
  background-color: #fff;
  margin-left: 15px;
  cursor: pointer;
}
.card {
  display: flex;
  flex-direction: column;
  min-height: 748px;
  width: 600px;

  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }

  .top {
    display: flex;
    flex-direction: column;
    margin-top:-20px;

    h3 {
      margin: 41px 0 24px 52px;
      font-weight: bold;;
      font-size: 25px;
      color: #270645;
    }

    .pupils {
      margin: 0 0 18px 51px;
      font-weight: bold;;
      font-size: 25px;
      color: #270645;
    }
  }

  .form {
    margin: -8px 52px;
    display: flex;
    flex-direction: column;
    
    .text-file{
      &::deep  .error--text{
      color: blue!important;
    }
    }
  }
   .showLimit{
      margin-left: 52px;
  margin-top:20px;
  margin-bottom: -10px;
      .limintContent{
      font-weight:bold;
}
    }

  .footer {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
   

    .price {
      display: block;
      font-family: Helvetica;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      /* line-height: 40px; */
      color: #766983;
    }

    .sub {
      margin: 14px auto;
      width: 450px;
      height: 51px;
       background-color: #1d42ff;
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
      font-size: 20px;
      color: #ffffff;
    }
  }
  &.mobile {
    .top {
      margin-left: -12px;
      .pupils {
        width: 90%;
        margin: 0 0 18px 52px;
        word-wrap: break-word;
      }
    }
    .form {
      margin-left: 40px;
    }

    .footer {
      margin-left: -12px;
      .sub {
        width: 80%;
      }
      
      .showLimit {
        margin-left: 50px;
        flex-direction: column !important;
        .maxTxt {
          margin-left: 0px !important;
        }
      }
    }

  }
}

img {
  display: inline-block;
  width: 126px;
  height: 126px;
  background-color: #8a73ff;
  border-radius: 5px;
  margin: 1px 22px 10px 52px;
}

span {
  display: flex;
  align-items: center;
  font-family: Helvetica;
  color: #270645;
}

.title {
  font-weight: bold!important;
  font-size: 15px!important;
  font-weight: normal;
  font-stretch: normal;
  color: #270645;
}
.subtitle {
  font-family: Helvetica;
  font-size: 15px;
  color: #766983;
  margin-top:-5px;
}
.sub-dis {
    position: relative;
    pointer-events: none;
    background-image: linear-gradient(
      #766983, 
      #766983), 
     linear-gradient(
      #270645, 
      #270645) !important;
     background-blend-mode: normal, 
      normal;
     border-radius: 25px;
     opacity: 0.9;
}
.sub-dis::after {
    content: "";
    background-image: url(../../assets/loading.gif);
    background-size: 100%;
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 20px;
   margin-left: 10px;
  margin-top: 5px;
}
</style>
