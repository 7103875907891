<template>
    <div id="container">
        <v-card :class="{ card: true, mobile: isMobile }">
            <img class="clo" width="31px" height="31px" @click="closeDialog" src="@/assets/btn_close.png" alt="" />
            <div class="top">
                <h3>{{$t('messageMessage')}}</h3>
                <div class="auth">
                    <span class="groupName">{{currfriend.fansName}}</span>
                </div>
            </div>
            <div id="msgbox" ref="msgbox">
                <ul v-scroll:#msgbox="onScroll">
                    <li v-for="i in msgList" :key="i.id">
                        <img class="fansPhoto" :src="i.fansPhoto" alt />
                        <div class="cont">
                            <pre class="msg">{{ i.message }}</pre>
                            <span class="time">{{ i.date }}</span>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="ti-tx">
                
            </div>
            <div class="footer" ref="footer">
                <v-textarea class="txt" label placeholder v-model="msgtxt" outlined auto-grow rows="1"></v-textarea>
                <img class="sub" src="../../assets/icons/btn_send.png" @click="subCommit" />
     
            </div>
        </v-card>

        <PromptBox ref="promptBox"></PromptBox>
    </div>
</template>

<script>
    import PromptBox from "@/components/PromptBox.vue"

    import api from "@/api";
    import {
        getFileSrc
    } from "@/utils/file";
    import img from '@/assets/default.png';
    const WORK_KEY = "WORK";

    //留言弹层
    export default {
        components: {
            PromptBox
        },
        data: () => ({
            open: true,
            tineValue: "",
            senderPhoto: "",
            msgtxt: "",
            img,
            loading: false,
            msgList: [],
            totalPage: 0,
            pageNumber: 1,
            pageSize: 20,
            pointer: 0,
        }),
        props: {
            currfriend: {
                type: Object
            }
        },
        computed: {
            isMobile: function() {
                return this.$vuetify.breakpoint.mobile;
            },
        },
        watch: {
            msgtxt() {
                this.txtKeyup();
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            goto() {
                const { scrollHeight } = this.$refs.msgbox;

                return this.$vuetify.goTo(scrollHeight - this.pointer, {
                    container: this.$refs.msgbox,
                    duration: 300,
                    offset: 0,
                    easing: "easeInOutCubic",
                });
                },
                onScroll(e) {
                const { scrollTop, scrollHeight, clientHeight } = e.target;
                this.pointer = scrollHeight - clientHeight - scrollTop;
                if (scrollTop < 80 && !this.loading && this.pageNumber <= this.totalPage) {
                    this.getList();
                }
                },
                async getList() {
                this.loading = true;

                let params = {
                    pageNumber: this.pageNumber,
                    pageSize: this.pageSize,
                    nftAddress:this.currfriend.fansAddress,
                   nftId:this.currfriend.nftId
                };
                let res = await api.home.messageRecordGroup(params);
                let param = {
                   address: this.$store.state.did,
                   chainType: this.$store.state.chainType
               };
               let info = await api.home.loopringUserInfo(param);
			   if(!info.data.profilePhoto){
				     info.data.profilePhoto= "QmcBGzXMUrmu151Q2K37z9mdokd2fd6b2NsPk2B9f7eLP6"
			   }
               let photo = await getFileSrc(WORK_KEY, info.data.profilePhoto);
               this.senderPhoto = photo; 
               if(photo=="null" || !photo) photo = img;
                this.totalPage = res.data.totalPage;
                this.pageNumber++;
                const list = res.data.list.map((v) => {
                    const item = {
                    id: v.id,
                    message: v.content,
                    fansPhoto: this.senderPhoto,
                    date: this.timestampToTime(v.sendTime),
                    };
          
                    return item;
                });
                this.msgList = list.concat(this.msgList);
                this.$nextTick(async () => {
                    await this.goto();
                    this.loading = false;
                });
            },
            async init(value) {
               // document.getElementsByTagName("textarea")[0].addEventListener("change", this.txtKeyup);
               if(value) {
                   this.currfriend = value;
               };
               if (this.currfriend.fansAddress && this.currfriend.fansAddress.length > 12) {
                   this.tineValue = this.currfriend.fansAddress.substr(0, 6) + "..." + this.currfriend.fansAddress
                       .substr(-6)
               }
               
                this.msgList = [];
                this.getList();
               
            },
            //提交转送数据
            async subCommit() {
                if (!this.msgtxt) {
                    this.$refs.promptBox.show("Information cannot be empty");
                    return;
                }
                let params = {
                        sender:this.$store.state.did,
                    mobilstyle:"Android",
                    nftAddress:this.currfriend.fansAddress,
                    lang:"zh",
                    content:this.msgtxt,
                     nftId:this.currfriend.nftId
                };
                // api.home.message(params);
                api.home.messageGroup(params);

                this.msgList.push({
                    // id: v.id,
                    message: this.msgtxt,
                    fansPhoto: this.senderPhoto,
                    date: this.timestampToTime(new Date().getTime())
                })
                
                this.pointer = 0;
                this.goto();
                
                this.msgtxt = "";
                // this.open = false
                // this.$emit('getOpen', this.newOpen);
            },
            //关闭弹框，发送open值
            closeDialog() {
                this.open = false;
                this.$emit("getOpen", this.open);
                // console.log(this.$store.state.userAddress);
            },
            //进入小狐狸
            foxBtn() {
                alert(111);
            },
            timestampToTime(time) {
                let date = new Date(time)
                let Y = date.getFullYear() + '-';
                let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
                let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
                let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
                let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
                let s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
                let strDate = Y + M + D + h + m + s;
                return strDate;
            },
            txtKeyup() {
                // console.log(e.target.offsetTop, this.$refs.footer.offsetTop);
                let h = this.$refs.footer.offsetTop - 75;
                this.$refs.msgbox.style.height = h + "px";
            }
        },
    };
</script>

<style lang="scss" scoped>
    .width-18 {
        display: inline-block;
        width: 100px !important;
        overflow: hidden !important;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
    }

    .card {
        width: 400px;
        height: 600px;

        // min-height: 600px;
        .clo {
            width: 25px;
            height: 25px;
            display: block;
            position: absolute;
            right: 15px;
            top: 10px;
            background-color: #fff;
            border-radius: 50%;
            cursor: pointer;
        }

        .top {
            padding: 0 25px;
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid #eee;

            h3 {
                line-height: 50px;
                font-size: 20px;
               font-family:Helvetica;
                color: #270645;
            }

            .auth {
                position: absolute;
                // top: 34px;
                right: 40px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-end;
                height: 50px;

                span {
                    width: 54px;
                    height: 13px;
                   font-family:Helvetica;
                    font-size: 13px;
                    font-weight: bold;
                    font-stretch: normal;
                    letter-spacing: 0px;
                    color: #270645;
                    margin-right: 15px;
                    text-align: right;
                }

                .val {
                    width: 100px;
                    height: 10px;
                    font-family:Helvetica;
                    font-size: 10px;
                    font-weight: normal;
                    font-stretch: normal;
                    letter-spacing: 0px;
                    line-height: 8px;
                    color: #270645;
                    margin-top: 7px;
                    text-align: right;
                }
                
                .groupName {
                    width: 200px;
                    height: 38px;
                    color: #766983;
                    font-family:Helvetica;
                    font-size: 10px;
                    font-weight: normal;

                }
            }
        }

        #msgbox {
            margin: 10px 25px;
            height: 470px;
            overflow: hidden;
            overflow-y: auto;

            ul {
                list-style: none;
                padding-left: 0;

                li {
                    position: relative;
                    top: 0;
                    left: 0;
                    margin-top: 12px;
                    // min-height: 60px;

                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        display: inline-block;
                        width: 30px;
                        height: 30px;
                        margin-top: 5px;
                    }

                    .cont {
                        clear: both;
                        display: inline-block;
                        // width: 300px;
                        min-height: 45px;
                        // margin: 0 0 19px 66px;
                        margin-left: 40px;
                        display: flex;
                        flex-direction: column;

                        .msg {
                            width: 98%;
                            // min-height: 17px;
                            font-family:Helvetica;
                            font-size: 14px;
                            // font-weight: normal;
                            // font-stretch: normal;
                            // letter-spacing: 0px;
                            color: #270645;

                            white-space:pre-wrap;
                            white-space:-moz-pre-wrap;
                            white-space:-pre-wrap;
                            white-space:-o-pre-wrap;
                            word-wrap:break-word;
                        }

                        .time {
                            // margin-left: 11px;
                            // width: 84px;
                            // height: 9px;
                            font-family:Helvetica;
                            font-size: 10px;
                            font-weight: normal;
                            font-stretch: normal;
                            letter-spacing: 0px;
                            color: #270645;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }

        .ti-tx {
            margin: 25px 28px;

            .title {
                width: 85px;
                height: 17px;
               font-family:Helvetica;
                font-size: 15px;
                font-weight: bold;
                font-stretch: normal;
                letter-spacing: 0px;
                color: #270645;
            }

            .txt {
                width: 350px;
                height: 40px;
                max-height: 100px;
                background-image: linear-gradient(#ffffff, #ffffff),
                    linear-gradient(#f8f6fd, #f8f6fd);
                background-blend-mode: normal, normal;
                
                &::v-deep textarea{
                    max-height: 100px;
                }
            }
        }

        .footer {
            display: flex;
            align-items: flex-end;
            position: absolute;
            left: 25px;
            right: 25px;
            bottom: -15px;
            
            .txt {
                margin-right: 10px;
                &::v-deep .v-input__slot {
                    min-height: 40px !important;
                    display: flex;
                    align-items: center;
                }
                &::v-deep textarea{
                    max-height: 100px;
                    color: #270645;
                    font-size: 14px;
                    line-height: 22px;
                    padding-top: 3px;
                }
            }
            
            .sub {
                width: 36px;
                height: 36px;
                margin-bottom: 33px;
                cursor: pointer;
            }
            
            &::v-deep textarea {
                margin-top: 5px !important;
            }
        }

        &.mobile {
            .footer {
                .sub {
                    width: 36px;
                    height: 36px;
                }
            }
        }
    }

    .fansPhoto {
        border-radius: 50%;
    }

    .v-card {
        box-shadow: 0px 0px 9px 0px rgb(0 0 0 / 9%) !important;
    }
    
    
    .mobile.card {
        width: 350px !important;
        height: 600px;
    }
</style>
