<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        src="@/assets/btn_close.png"
        alt=""
      />
      <div class="topinfo">
        <div class="top">
        <h3>{{ $t("popupMarketStartSale") }}</h3>
        <h3 class="pupils">{{ NftName }}</h3>
      </div>
      <div class="form">
        <div class="title mb-lg-3 mb-3">Token</div>
        <ChainSelectInput @change="chainChange"></ChainSelectInput>
      </div>
      <div class="showLimit">
         <!-- <span class="txt">{{ item.name }}</span> -->
         <span class="minTxt">Minimum price <span class="limintContent">{{currentMinPrice}} {{chainName}}</span></span> 
         <span class="maxTxt">Maximum price <span class="limintContent">{{currentMaxPrice}} {{chainName}}</span></span> 
      </div>
      <div class="form">
        <div class="d-flex flex-row" style="position: relative;">
          <div class="title mb-lg-3 mb-3">{{ $t("popupMarketSalesPrice") }} </div>
        <img  class="refresh mt-1 ml-2 "  src="@/assets/icons/icon_wen.png"  @mouseenter="moused()" @mouseleave="leave()"/>
        <div class="reserveContant" v-if="isShowRecerve">
					 <div class="des">{{contant}}</div>
				 </div>
        </div>
        
        <v-text-field
          ref="SalePrice"
          v-model="SalePrice"
          outlined
          :suffix="chain.name"
          type="input"
          :rules="[ rules.errorPrice ]"
        ></v-text-field>
      </div>
 
      <div class="maxamount mt-lg-n5 d-flex flex-row justify-space-between">
        <div class="b d-flex flex-row">
          <div class="amount mt-4">
            {{ $t("popupMarketMaximumPurchaseAmount") }}
          </div>
          <v-text-field
            class="txt"
            ref="amount"
            v-model="amount"
            height:5px
            full-width:false
            outlined
            :rules="[rules.maxCount]"
            v-if="isShow"
          ></v-text-field>
        </div>
        <div class="box2 mr-n4">
          <v-sheet class="sth">
            <v-switch v-model="switch1" inset></v-switch>
          </v-sheet>
        </div>
      </div>


        <div class="form mt-lg-3">
        <div class="ti-in">
          <span class="title" >Expiration</span>
          <v-select
          class="mt-3"
            :items="list"
            label="6 Month"
            v-model="validUntil"
            item-text="text"
            item-value="value"
            solo
            any
          ></v-select>
        </div>
      </div>
      </div>
      

      <div class="footer">
        <button class="sub" :class="{ 'sub-dis': isPay }" @click="subCommit">
          {{ $t("popupTransferCommit") }}
        </button>
      </div>
    </v-card>
    <!-- <PromptBox ref="promptBox"></PromptBox> -->
    <uComponents ref="ucom"></uComponents>
  </div>
</template>

<script>
import api from "@/api";
import ChainSelectInput from "@/components/ChainSelectInput.vue"

import Web3 from "web3";
const web3Obj = new Web3();
const { toShowValue,tokenId2Index} = require("../../utils/helper");

export default {
  components: { ChainSelectInput },
  name: "transfer",
  data: () => ({
     validUntil:5,
    priceLimit:[],
    currentMinPrice:"0",
    currentMaxPrice:"0",
    open: true,
    SalePrice: "",
    amount: "",
    switch1: "false",
    NftName: "",
  royaltyPercentage:"",
  chainName:'LRC',
    isShow: true,
    isPay: false,
    isShowRecerve:false,
    contant:'',
    totalRoyalty:"",
    rules: {
      // errorPrice:(value) => {
      //   if(value==0){
      //     return "Out of price range"; 
      //   }

      //   const pattern = /^((\+?[0-9][0-9]{0,8})\.([0-9]{1,4})$)|^(\+?[0-9][0-9]{0,8})$/;
      //   return pattern.test(value) || "Out of price range";
      //  },
      
    },
    chain: { },
  }),

  props: {
    address: {
      type: String,
    },
    onSaleTokenIds: {
      type: Array,
    },
    offSaleTokenIds: {
      type: Array,
    },
    name: {
      type: String,
    },
    parentValue: {
      type: Object,
    },
	
  },
  computed: {

    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
     list:function () {
      return  [
          {
            text: "1 Day",
            value: 1,
          },
          {
            text: "1 Week ",
            value: 2,
          },
          {
            text: "1 Month",
            value: 3,
          },
          {
            text: "3 Month",
            value: 4,
          },
          {
            text: "6 Month",
            value: 5,
          },
          {
            text: "1 Year ",
            value: 6,
          },
          {
            text: "Long term (5 years) ",
            value: 7,
          },
        ];
      
    },
    handler: function () {
      return {
        switch: this.switch1,
      };
    },
  },
  watch: {
    handler: {
      handler: function () {
        if (this.switch1 == true) {
          this.amount = 1;
          this.isShow = true;
        } else {
          this.amount = 0;
          this.isShow = false;
        }
      },
      deep: true,
    },
  },
  async mounted() {

    try{
    // init
      this.contant = "This NFT will be listed for sale at this price. After sale, N% will be deducted as the creators' Royalty and platform service fee"
	  this.royaltyPercentage=this.parentValue.royaltyPercentage;
	  if(this.royaltyPercentage==null){
		this.royaltyPercentage=0;  
    }
    let uptickRoy = process.env.VUE_APP_UPTICK_ROYALTY_PERCENTAGE
	api.home.getSystemParams({keys:"uptickServiceFee"}).then((systemParams) => {
		
	 uptickRoy=systemParams.data[0].value
	 this.totalRoyalty = Number(this.royaltyPercentage) + Number(uptickRoy)
	   this.contant = this.contant.replace("N%",this.totalRoyalty+"%")
	});
	

	  console.log("zxx",this.parentValue)
      this.chain.name = this.$walletUnit;
      this.chain.tokenId = 1;
      
      this.NftName = this.name;
      let amount = await this.LRCHandler.getUserBalances(1);
   
      this.amount = 1;
      this.switch1 =false
      this.priceLimit = await this.LRCHandler.getPriceLimit(
          this.parentValue.contractAddress
      );
       console.log("wxl --- priceLimit",this.priceLimit)
       

       //  todo dev 
      let currentMinPrice =  toShowValue(web3Obj,1,this.priceLimit[3].marketOrderInfo["minimum"]);
      this.currentMaxPrice =  toShowValue(web3Obj,1,this.priceLimit[3].marketOrderInfo["maximum"]);
  
      
    let tradeCost = this.priceLimit[3].tradeCost;
    let tradePrice =  Number(toShowValue(web3Obj,1,tradeCost) *3);
    console.log("wxl --- tradePrice 444444",tradePrice)
    if(tradePrice > currentMinPrice){
      this.currentMinPrice = parseFloat(Math.ceil(tradePrice*10000))/10000
      
    }else{
      this.currentMinPrice =  parseFloat(Math.ceil(currentMinPrice*10000))/10000
    }
    

      this.rules.errorPrice = (value) => {
        if(value<parseFloat(this.currentMinPrice) || value>parseFloat(this.currentMaxPrice)){
          return "Out of price range"; 
        }
        const pattern = /^\d*(?:\.\d{0,4})?$/;
        return pattern.test(value) || "Out of price range";
       }
    }catch(e){
      console.log("market mounted error",e);
    }
 

  },
  methods: {

    async chainChange(chain,coinname) {
      console.log("wxl --- chainChange",chain,coinname)
      this.chainName = coinname
      let amount = await this.LRCHandler.getUserBalances(chain.tokenId);
      this.chain = chain;
      
      let index = tokenId2Index(chain.tokenId)
      
      let currentMinPrice =  toShowValue(web3Obj,chain.tokenId,this.priceLimit[index].marketOrderInfo["minimum"]);
      this.currentMaxPrice =  toShowValue(web3Obj,chain.tokenId,this.priceLimit[index].marketOrderInfo["maximum"]);
      
    let tradeCost = this.priceLimit[index].tradeCost;
    let tradePrice =  Number(toShowValue(web3Obj,chain.tokenId,tradeCost)*3);
     console.log("wxl --- tradePrice 55555",tradePrice)
    console.log(" ",tradePrice)
    if(tradePrice > currentMinPrice){
      this.currentMinPrice = parseFloat(Math.ceil(tradePrice*10000))/10000
      
    }else{
      this.currentMinPrice =  parseFloat(Math.ceil(currentMinPrice*10000))/10000
    }

    },
    verify() {
      let saleVer = this.$refs.SalePrice.validate(true);
      let amountVer = true;
      if (this.switch1 == true) {
        amountVer = this.$refs.amount.validate(true);
      }

      !saleVer ? this.$refs.SalePrice.focus() : "";
      !amountVer ? this.$refs.amount.focus() : "";
      return saleVer && amountVer;
    },


    async subCommit() {
      try {
          if (!this.verify()) {
          return;
      }
      if(parseFloat(this.SalePrice) < parseFloat(this.currentMinPrice)){
        this.$toast("error", this.$t("salePriceTooSmall") );
        return;
      }

      if(parseFloat(this.SalePrice) > parseFloat(this.currentMaxPrice)){
        this.$toast("error", this.$t("salePriceTooBig"));
        return;
      }
      console.log("wxl --- ",this.parentValue)
      if(this.amount > this.parentValue.amount){
        this.$toast("error", "quantity not sufficient");
        return;
      }
    
      this.isPay = true;
      let resTokenId=await api.home.getNftTokenId({"owner":this.$store.state.did,"tokenAddress": this.parentValue.contractAddress,"nftId": this.parentValue.nftId});
		  if(resTokenId==null||resTokenId.data==null){
        this.$toast("error", "invalid NFT");
        return ;   
      }
      debugger
      console.log("wxl --- parentValue",this.parentValue)
      console.log("wxl --- auctionPrice",this.auctionPrice,this.chain.tokenId,this.parentValue.haveAmount, this.parentValue.nftData,resTokenId.data)
      let validUntil ;
      if(this.validUntil == 1){
		  // 日期前+15天
        validUntil = Math.round(Date.now() / 1000) + 16 * 86400
      }else if(this.validUntil == 2){
         validUntil = Math.round(Date.now() / 1000) + 22 * 86400
      }else if(this.validUntil == 3){
         validUntil = Math.round(Date.now() / 1000) + 45 * 86400
      }else if(this.validUntil == 4){
         validUntil = Math.round(Date.now() / 1000) + 105 * 86400
      }else if(this.validUntil == 5){
         validUntil = Math.round(Date.now() / 1000) + 195 * 86400
      }else if(this.validUntil == 6){
         validUntil = Math.round(Date.now() / 1000) + 380 * 86400
      }else if(this.validUntil == 7){
         validUntil = Math.round(Date.now() / 1000) +  1840 * 86400
      }

      let signature = await this.LRCHandler.makerOrder(
        resTokenId.data,
        this.parentValue.nftData,
        this.parentValue.amount.toString(),
        // "ipfs://"+this.parentValue.imgUrl,
        this.chain.tokenId,
        this.SalePrice,
        this.totalRoyalty,
        validUntil
      );
        if(!signature){
          this.$toast("error", "Signature request was rejected");
               this.isPay = false;
         return
      }

      // let tokenAddress = window.sessionStorage.getItem("TokenAddress");
      let makerOrderJson = {
        "exchange": signature.exchange,
        "accountId": signature.accountId,
        "storageId": signature.storageId,
        "sellToken": signature.sellToken,
        "buyToken": signature.buyToken,
        "allOrNone": signature.allOrNone,
        "fillAmountBOrS": signature.fillAmountBOrS,
        "validUntil": signature.validUntil,
        "maxFeeBips": signature.maxFeeBips
      };
      let buyLimit = "";
      if (this.switch1 == true) {
          buyLimit = this.amount;
      } else {
          buyLimit = 0;
      }
	
      let param = {
        "owner": this.$store.state.did,
        "nftAddress": this.parentValue.contractAddress,
        "nftId": this.parentValue.nftId,
        "marketPrice": this.SalePrice,
        "tokenType": this.chain.name,
		    "limit": buyLimit,
        "makerOrderJson": JSON.stringify(makerOrderJson),
        "signature": signature.eddsaSignature,
        "listAmount":this.parentValue.amount,
        "validUntil":validUntil
      
      };
      let nftlist_res = await api.home.nftList(param);

      if(nftlist_res.success) {
        console.log("wxl ---- 44444")
        //  this.$emit("getOpen", 1);
        this.$emit("getOpen", false);
        this.isPay = false;

         let t = this.$t("onSaleSuccess");
                  let reg = new RegExp("【N】", "g"); //g代表全部
                  let regN = new RegExp("<br/>", "g"); //g代表全部
                  let name = "【" + this.name + "】";
                  let title = t.replace(reg, name).replace(regN, " ");
                   let isShowTip = localStorage.getItem("EVM_isShowTip_"+this.$store.state.did)
            if(isShowTip == 'true'){
              this.$mtip({
                    title: title,
                  });
            }
                 
         window.eventBus.$emit("collectionPay");
        // window.location.reload();
       
      } 
      else {
        this.$toast("error", this.$t("transactionInprogressError"));
        this.isPay = false;
      }
        
      } catch (error) {
         console.log(error)
        this.$toast("error", this.$t("transactionInprogressError"));
        this.isPay = false
      }
     
    
    },
    		moused(){
				console.log("2222")
				this.isShowRecerve = true

			},
			leave(){
				console.log("1111")
			 this.isShowRecerve = false
			},

    closeDialog() {
      this.open = false;
      this.$emit("getOpen", this.open);
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  min-height: 600px;
  width: 600px;

  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }
  .topinfo{
      margin-left: 30px;
    margin-right: 30px;
     .top {
    margin-left: 20px;

    h3 {
      margin: 16px 0 10px;
      font-weight: bold;;
      font-size: 25px;
      color: #270645;
    }

    .pupils {
      margin: 0 0 22px;
  
      font-size: 20px;
      color: #270645;
    }
  }

  .form {
    margin: 7px 20px;
    &::v-deep .v-input__slot {
      height: 56px;
     
	  
	}
   &::v-deep .v-text-field.v-text-field--solo .v-label {
      color: black;
    }
  
    img{
      width: 25px;
      height: 25px;
      background-color:white;
    }
    .title {

      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      color: #270645;
    }
    	.reserveContant{
					position: absolute;
					right: 150px;
					top: 10px;
					width: 212px;
					height: 101px;
					background-color: #270645;
					border-radius: 3px;
					z-index:1;
					.des{
						margin: 15px;
						width: 183px;
						height: 71px;
						font-family: MicrosoftYaHei;
						font-size: 12px;
					font-weight: normal;
					font-stretch: normal;
					letter-spacing: 0px;
					color: #ffffff;
					line-height: 15px;
					}
					
	
				}
  }

  .maxamount {
    margin: 0 20px;

    .amount {
      margin-right: 18px;
      font-weight: bold;
      font-size: 15px;
      width: 212px;
      height: 17px;
      color: #270645;
      display: inline-block;
    }

    .txt {
      width: 98px;
      height: 55px;
      background-image: linear-gradient(#ffffff, #ffffff),
        linear-gradient(#f8f6fd, #f8f6fd);
      background-blend-mode: normal, normal;
      border-radius: 5px;
      display: inline-block;
    }

    .sth {
      margin-left: 108px;
      width: 60px;
      height: 35px;
      // background-color: #270645;
      display: inline-block;
      position: relative;
      right: 0px;
      bottom: 0px;
      border-radius: 18px;
    }
  }
  }

 

  .footer {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 25px auto;

    .price {
      display: block;
      font-family: Helvetica;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 40px;
      color: #766983;
    }

    .sub {
      margin: 0 auto;
      width: 450px;
      height: 51px;
      background-color: #1d42ff;
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
      font-size: 20px;
      color: #ffffff;
    }
  }
  &.mobile {
    .maxamount {
      .sth {
        margin-left: 50px;
      }
    }
    .footer {
      margin: 10px 10%;
      .sub {
        width: 80%;
      }
    }
  }
}

img {
  display: inline-block;
  width: 126px;
  height: 126px;
  background-color: #8a73ff;
  border-radius: 5px;
  margin: 1px 22px 10px 52px;
}

span {
  align-items: center;
  font-family: Helvetica;
  color: #270645;
}
.sub-dis {
  position: relative;
  pointer-events: none;
  background-image: linear-gradient(#766983, #766983),
    linear-gradient(#270645, #270645) !important;
  background-blend-mode: normal, normal;
  border-radius: 25px;
  opacity: 0.9;
}
.sub-dis::after {
  content: "";
  background-image: url(../../assets/loading.gif);
  background-size: 100%;
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  margin-top: 5px;

  // box-shadow: 0 0 10px #fff;
  // border-radius: 50%;
}

.showLimit{
  margin-left: 20px;
  margin-top:10px;
  margin-bottom: 10px;
}

.maxTxt{
  margin-left:20px;
}

.limintContent{
  font-weight:bold;
}

.mobile {
  .showLimit {
    display: flex;
    flex-direction: column;
    .maxTxt {
      margin-left: 0px;
    }
  }
  .maxamount {
    .sth {
      margin-left: 0px !important;
    }
    .amount {
      margin-right: unset;
      width: 138px;
      height: unset;
    }
    .txt {
      width: 80px;
    }
  }
}

</style>
