<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img class="clo" @click="closeDialog" src="@/assets/btn_close.png" alt />
      <div class="top">
        <h3>Please select a sales model</h3>
      </div>
      <div class="link">
        <ul class="d-flex flex-lg-row flex-column">
          <li class="mr-lg-4" @click="fixedPriceBtn">
            <img
              src="@/assets/icons/sale_fixed.png"
              width="69px"
              height="69px"
              alt
            />
            <div class="infotit">{{$t('onsaleFixedPrice')}}</div>
            <div class="infos">{{$t('onsaleModifiabledPrice')}}</div>
          </li>
          <li v-if="parentValue.amount === 1 " class="" @click="StartSaleBtn">
            <img
              src="@/assets/icons/sale_auction.png"
              width="74px"
              height="71px"
              alt
            />
            <div class="infotitauc">{{$t('onsaleTimedAuction')}}</div>
            <div class="infos">{{$t('onSaleIssues')}}</div>
          </li>
          <li v-if="parentValue.amount !== 1 " class="onlyread">
            <img
              src="@/assets/icons/sale_auction1.png"
              width="74px"
              height="71px"
              alt
            />
            <div class="infotitauc">{{$t('onsaleTimedAuction')}}</div>
            <div class="infos">{{$t('onSaleIssues')}}</div>
          </li>
        </ul>
      </div>
    </v-card>
    <!-- 拍卖 -->
    <v-dialog v-model="Sale" persistent>
      <auction
        :address="address"
        :onSaleTokenIds="onSaleTokenIds"
        :offSaleTokenIds="offSaleTokenIds"
        :name="name"
        :parentValue="parentValue"
        v-bind="$attrs"
        v-on="$listeners"
        @getOpen="openSale"
      ></auction>
    </v-dialog>
    <!-- 开始售卖 -->
    <v-dialog v-model="openmarket" persistent>
      <market
        :address="address"
        :onSaleTokenIds="onSaleTokenIds"
        :offSaleTokenIds="offSaleTokenIds"
        :name="name"
        :parentValue="parentValue"
        v-bind="$attrs"
        v-on="$listeners"
        @getOpen="openMarket"
      ></market>
    </v-dialog>
  </div>
</template>

<script>
import Auction from "@/components/popup/auction.vue";
import Market from "@/components/popup/market.vue";
export default {
  components: { Auction, Market },
  props: {
    supplyLimit: {
      type: Number,
    },
    address: {
      type: String,
    },
    onSaleTokenIds: {
      type: Array,
    },
    offSaleTokenIds: {
      type: Array,
    },
    name:{
      type: String,
    },
    parentValue:{
      type: Object,
    },
  },
  data: () => ({
    open: false,
    sum: 1,
    openmarket:false,
    Sale:false
  }),
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  mounted(){
   console.log("ssssss",this.parentValue)

  },
  methods: {
    closeDialog() {
      this.$emit("getOpen", this.open);
    },
    StartSaleBtn() {
      this.$gtag.event('上架模式-Timed Auction', { 'event_category': 'Click', 'event_label': 'Timed Auction' })
      this.Sale = true;
    },
    openSale(e) {
      this.Sale = e;
    },
    fixedPriceBtn() {
      this.$gtag.event('上架模式-Fixed Price', { 'event_category': 'Click', 'event_label': 'Fixed Price' })
      this.openmarket = true;
    },
    openMarket(e) {
      this.openmarket = e;
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  width: 600px;
  height: 350px;
  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }

  .top {
    margin: 0 52px;
    display: flex;
    flex-direction: column;
    h3 {
      margin: 41px 0 15px;
      font-weight: bold!important;
      font-size: 25px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }
    p {
      font-family: Helvetica;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      margin-bottom: 29px;
    }
  }
  .link {
    margin: 0 52px 53px;
    ul {
      list-style: none;
      padding: 0;
      li {
        text-align: center;
        cursor: pointer;
        width: 240px;
        height: 200px;
        background-image: linear-gradient(#ffffff, #ffffff),
          linear-gradient(#f8f6fd, #f8f6fd);
        background-blend-mode: normal, normal;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
        border-radius: 5px;
        display: block;
        margin-bottom: 9px;
        img {
          display: block;
          margin: 38px auto 30px;
        }
        .infotit,
        .infotitauc {
          height: 22px;
          font-weight: bold;;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0px;
          line-height: 22px;
          color: #270645;
          margin: 0 auto 7px;
        }
        .infos {
          height: 12px;
          font-family: Helvetica;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          line-height: 12px;
          color: #270645;
          margin-bottom: 17px;
        }
      }
      .onlyread {
        .infotitauc,
        .infos {
          color: #ccc;
        }
      }
      li:hover {
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.36);
      }
    }
  }
  &.mobile {
    height: 550px;
    .top {
      h3 {
        font-size: 20px;
      }
      p {
        height: auto;
      }
    }
    .link {
      ul {
        li {
          img {
            margin: 35px auto;
          }
        }
      }
    }
  }
}
</style>
